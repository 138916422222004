import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

export const brandPrimary = defineStyle({
  background: "brand.500",
  color: "CI_Purple1",
  _hover: {
    background: "brand.300",
  },
  // let's also provide dark mode alternatives
  _dark: {
    background: "brand.500",
    color: "black",
  },
});
export const brandRevert = defineStyle({
  background: "brand.500",
  color: "CI_Purple1",
  _hover: {
    background: "brandSecondary.500",
    color: "white",
  },
});
export const brandSecondary = defineStyle({
  background: "brandSecondary.500",
  color: "white",
  borderRadius: "full",
  _disabled: {
    opacity: 1,
    bg: "888",
  },
  _hover: {
    _disabled: {
      bg: "888",
    },
    bg: "888",
    opacity: 0.7,
  },
  // let's also provide dark mode alternatives
  _dark: {
    background: "brandSecondary.500",
    color: "white",
    _hover: {
      background: "brandSecondary.300",
    },
    _active: {
      background: "brandSecondary.300",
      color: "brandGreen.500",
    },
    _disabled: {
      background: "brandGray.300",
    },
  },
});
export const brandGray = defineStyle({
  background: "#f5f5f5",
  borderRadius: "3xl",
  border: "1px solid ",
  borderColor: "icon_Gray",
  color: "555",
  _hover: {
    color: "icon_Gray",
  },
});
export const brandRed = defineStyle({
  background: "brandGray.200",
  color: "brandGray.500",
  _hover: {
    background: "brandRed.300",
    color: "white",
  },
  _active: {
    background: "brandRed.500",
    color: "white",
  },
  _disabled: {
    opacity: 0.5,
  },
});
export const pillDefault = defineStyle({
  borderRadius: "3xl",
});

// Defining a custom variant
const pillVariant = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    bg: `${c}.500`,
    color: "white",
    borderRadius: "3xl",

    _disabled: {
      bg: `icon_Gray`,
    },

    _hover: {
      _disabled: {
        bg: `888`,
      },
      // transform: "scale(1.05, 1.05)",
      bg: `${c}.500`,

      _dark: {
        // bg: `${c}.300`,
      },
    },

    _active: {
      bg: `${c}.500`,
      // transform: "scale(1, 1)",

      _dark: {
        bg: `${c}.500`,
      },
    },
  };
});

const buttonBase = defineStyle((props) => ({
  background: "#FFFFFF1A",
  backgroundColor: "#FFFFFF1A",
  color: "#B8B8B8",
  fill: "#B8B8B8",
  ":hover": {
    background: "#FFFFFF33",
    backgroundColor: "#FFFFFF33",
    color: "white",
    fill: "white",
  },
  ":focus": {
    borderColor: "#E1FF01",
    borderWidth: "1px",
  },
  ":active": {
    background: "rgba(255, 255, 255, 0.4)",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    color: "white",
    fill: "white",
  },
  ":disabled": {
    color: "#88888880",
    fill: "#88888880",
  },
}));

export const buttonTheme = defineStyleConfig({
  // baseStyle: buttonBase,
  variants: {
    brandPrimary,
    brandSecondary,
    brandRed,
    pill: pillVariant,
    pillDefault,
    brandGray,
    brandRevert,
    iconBase: buttonBase,
  },
});
export const iconButtonTheme = defineStyleConfig({
  baseStyle: {
    backgroundColor: "red",
    background: "red",
  },
  variants: {
    brandPrimary,
    brandSecondary,
    brandRed,
    pill: pillVariant,
    pillDefault,
    brandGray,
    brandRevert,
    iconBase: buttonBase,
  },
});

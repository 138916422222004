import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

(async function main() {
  i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(HttpApi);

  // this the overview
  // for all options read: https://www.i18next.com/overview/configuration-options
  // if (import.meta.env.VITE_APP_STAGE !== "prod") {
  //   const { i18nextPlugin } = await import("translation-check");
  //   i18n.use(i18nextPlugin);
  // }

  i18n.init({
    debug: import.meta.env.DEV,
    backend: {
      loadPath: "/i18n/{{lng}}/{{ns}}.json",
    },
    fallbackLng: "en",
  });
})();

export default i18n;

import { useEffect } from "react";
import type { FallbackProps } from "react-error-boundary";
import * as Sentry from "@sentry/browser";
import { isTRPCClientError } from "@utils/trpc";

export const captureException = (error: unknown, ctx?: any) => {
  if (isTRPCClientError(error)) {
    console.log("trpc error", error);
    Sentry.captureException(error, ctx);
    return;
  } else if (error instanceof Error) {
    console.log("standard error", error);
    Sentry.captureException(error, ctx);
    return;
  }
  Sentry.captureException(error);
  console.log("unknown error");
  console.error(error);
};

/**
 * TODO: How to make it work?
 * @param props
 * @returns
 */
export const AppError = (props: FallbackProps) => {
  useEffect(() => {
    if (props.error) {
      Sentry.captureException(props.error);
      console.error(props.error);
    }
  }, [props.error]);
  return <></>;
  // return (
  //   <div
  //     style={{
  //       position: "absolute",
  //       top: 0,
  //       left: 0,
  //       right: 0,
  //       backgroundColor: "red",
  //       color: "white",
  //       padding: 10,
  //       textAlign: "center",
  //     }}
  //   >
  //     <div>Faced Error when request</div>
  //     {/* <div>{props.error?.message}</div> */}
  //   </div>
  // );
};

// theme.js

// 1. import `extendTheme` function
import { defineStyle, extendTheme } from "@chakra-ui/react";
import { buttonTheme, iconButtonTheme } from "./button";
import { accordionTheme } from "./components/Accordion";
import { checkboxTheme } from "./components/Checkbox";
import { menuTheme } from "./components/Menu";
import { modalTheme } from "./components/Modal";
import { switchTheme } from "./components/Switch";
import { inputTheme } from "./components/Input";
import { TextTheme, textStyles } from "./components/Text";
import { linkTheme } from "./link";

const themeSetting: Parameters<typeof extendTheme>[0] = {
  fonts: {
    // body: "Plus Jakarta Sans, system-ui, sans-serif",
    inter: "'Inter', system-ui, sans-serif",
    body: "'Inter', system-ui, sans-serif",
  },
  semanticTokens: {
    // TODO: add semantic tokens
    colors: {
      "2nd_Blue": "#7BAEFA",
      "2nd_Green": "#ADDF1D",
      "2nd_Orange": "#FF9153",
      CI_Purple1: "#5C22DC",
      CI_Purple2: "#9F88FC",
      CI_Gray1: "#E6E6E6",
      CI_Gary2: "rgba(51, 51, 51, 1)",
      icon_Gray: "#B8B8B8",
      "888": "#888",
      "555": "#555555",
      BarBlack: "#1C1C1C",
      CI_Green1: "#E1FF01",
      CI_Gary1: "#E6E6E6",
      Black: "#151515",
      f5f5f5: "#F5F5F5",
    },
  },
  fontSizes: {
    "14": "3.5rem",
    "8": "2rem",
  },
  colors: {
    brand: {
      300: "#F1FD97",
      500: "#E1FF01",
    },
    brandBlue: {
      // 2nd_Blue
      300: "#7BAEFA",
    },
    brandOrange: {
      // 2nd_Orange
      500: "#FF9153",
    },
    brandGreen: {
      // CI_Green2
      300: "#F1FD97",
      // CI_Green1
      500: "#E1FF01",
      // 2nd_Green
      700: "#ADDF1D",
    },
    brandRed: {
      100: "#FFE2E2",
      // Waning3
      300: "#EB9898",
      // Warning1
      500: "#E26D6D",
    },
    brandSecondary: {
      // CI_Purple2
      200: "#9F88FC",
      300: "#9F88FC",
      500: "#6C2CFF",
    },
    brandGray: {
      100: "#F5F5F5",
      // CI_Gary1
      200: "#E6E6E6",
      // icon_Gray
      300: "#B8B8B8",
      400: "#888",
      // CI_Gary20000
      500: "#333333",
      800: "#202229",
      900: "#1C1C1C",
    },
  },
  components: {
    Button: buttonTheme,
    IconButton: iconButtonTheme,
    Link: linkTheme,
    Accordion: accordionTheme,
    Text: TextTheme,
    Switch: switchTheme,
    Menu: menuTheme,
    Modal: modalTheme,
    Checkbox: checkboxTheme,
    Input: inputTheme,
  },
  styles: {
    global: (props) => ({
      ".bar": {
        backgroundColor: "#7F9900",
      },
      "html, body": {
        fontSize: "16px",
        color: props.colorMode === "dark" ? "white" : "gray.600",
        backgroundColor: props.colorMode === "dark" ? "#333" : "#F5F5F5",
      },
    }),
  },
};

// 2. Add your color mode config
const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// 3. extend the theme
export const theme = extendTheme({
  config,
  textStyles: {
    ...textStyles,
  },
  ...themeSetting,
});
// console.log({ theme });

export default theme;

import React, { useState } from "react";
import "./App.css";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { Routes } from "./utils/route";
import theme from "./styles/theme";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { trpc, trpcClientReact as tClient } from "./utils/trpc";
import { Container as ModalContainer } from "react-modal-promise";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import { captureException } from "@components/ErrorBoundary";
import NiceModal from "@ebay/nice-modal-react";

export function Trpc({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            onError(err) {
              captureException(err);
            },
          },
          queries: {
            onError(err) {
              captureException(err);
            },
            retry: import.meta.env.PROD,
            retryDelay: (attemptIndex) =>
              Math.min(1000 * 2 ** attemptIndex, 30000),
            refetchOnWindowFocus: import.meta.env.MODE === "production",
            refetchOnReconnect: import.meta.env.MODE === "production",
          },
        },
      })
  );
  const [trpcClient] = useState(() => tClient);
  return (
    <QueryErrorResetBoundary>
      <ErrorBoundary onError={captureException} fallback={<></>}>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            {children}
            {/* {import.meta.env.MODE === "development" && (
              // <ReactQueryDevtools initialIsOpen={false} />
            )} */}
          </QueryClientProvider>
        </trpc.Provider>
      </ErrorBoundary>
    </QueryErrorResetBoundary>
  );
}

export const App = () => {
  return (
    <Trpc>
      <ChakraProvider resetCSS theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <NiceModal.Provider>
          <Routes />
        </NiceModal.Provider>
        <ModalContainer />
      </ChakraProvider>
    </Trpc>
  );
};

import { TwotoneLogoSvg } from "@assets/index";
import { Box, Center, Link, Text } from "@components";

export default function NotFound() {
  return (
    <Box className="full-height">
      <Center className="full-height">
        <Box textAlign={"center"}>
          <Box as={TwotoneLogoSvg} />
          <Text variant={"headline_4"} mt={4}>
            Ops.. 404
          </Text>
          <Box mt={4}>
            <Link href={"/"}>Go back to home</Link>
          </Box>
        </Box>
      </Center>
    </Box>
  );
}

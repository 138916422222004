// utils/trpc.ts
import {
  createTRPCProxyClient,
  createTRPCReact,
  httpBatchLink,
  inferReactQueryProcedureOptions,
  TRPCClientError,
} from "@trpc/react-query";
// import type { AppRouter } from "../../../services/server/routers/_app";
import type { AppRouter } from "@fireflim-app/services";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import superjson from "superjson";

export const trpc = createTRPCReact<AppRouter>();
export type RouterInput = inferRouterInputs<AppRouter>;
export type RouterOutput = inferRouterOutputs<AppRouter>;
export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;

export function isTRPCClientError(
  cause: unknown
): cause is TRPCClientError<AppRouter> {
  return cause instanceof TRPCClientError;
}

const injectToken = (options) => {
  if (localStorage.getItem("ff:token")) {
    options = {
      ...options,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("ff:token")}`,
      },
    };
  }
  return options;
};

const batchLink = httpBatchLink({
  url: import.meta.env.VITE_APP_API_URL + "/trpc",
  fetch(url, options) {
    options = injectToken(options);
    return fetch(url, {
      ...options,
    });
  },
});

const opt = {
  transformer: superjson,
  links: [batchLink],
};

export const trpcClient = createTRPCProxyClient<AppRouter>(opt);

export const trpcClientReact = trpc.createClient(opt);

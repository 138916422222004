import ReactDOM from "react-dom/client";
import { App } from "./app";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./utils/i18n";

Sentry.init({
  dsn: "https://4ce15a0477ad4017bbe122072e8bc2b6@o4504634217725952.ingest.sentry.io/4504822309584896",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_APP_STAGE,
  enabled: ["dev", "prod"].includes(import.meta.env.VITE_APP_STAGE),
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

export const brandPrimary = defineStyle({
  color: "brand.500",

  // let's also provide dark mode alternatives
  _dark: {
    background: "brand.500",
    color: "black",
  },
});

export const linkTheme = defineStyleConfig({
  variants: { brandPrimary },
});

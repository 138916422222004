import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle(({ colorScheme }) => ({
  // define the part you're going to style
  container: {
    border: "1px solid",
    borderRadius: "full",
    borderColor:
      colorScheme === "blue" ? "transparent" : colorScheme ?? "brandGray.300",
  },
  thumb: {
    // bg: "red.500",
    boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.25)",
  },
  track: {
    _checked: {
      bg:
        colorScheme === "blue"
          ? "brandGreen.700"
          : colorScheme ?? "brandGreen.700",
    },
  },
}));

export const switchTheme = defineMultiStyleConfig({ baseStyle });

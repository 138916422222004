import { defineModule } from "zoov";
import jwt_decode from "jwt-decode";
// import create from "zustand";
import type { AnonymousUser, User } from "@types";
import axios from "axios";
import { RouterOutput, trpcClient } from "@utils/trpc";
import * as Sentry from "@sentry/browser";
import to from "await-to-js";

const client = axios.create({ baseURL: import.meta.env.VITE_APP_API_URL });

interface JWT {
  type: string;
  properties: (User & { accountId: string }) | AnonymousUser;
  iat: number;
}

const { use } = defineModule({
  token: localStorage.getItem("ff:token") as string,
  id: "",
  email: "",
  name: "" as string | null,
  isAnonymous: false,
  accountId: null as string | null,
  loadingAuth: true,
  emailVerified: false,
  meData: null as RouterOutput["user"]["me"] | null,
})
  .actions({
    logout(s) {
      s.token = "";
      s.id = "";
      s.email = "";
      s.name = "";
      s.isAnonymous = false;
      s.accountId = null;
      s.loadingAuth = false;
      localStorage.removeItem("ff:token");
      Sentry.setUser(null);
    },
    setAccount(s, acId) {
      s.accountId = acId;
    },
    updateUser(s, user: User) {
      s.email = user.email;
      if (user.name) s.name = user.name;
    },
    setupUser(state, user: RouterOutput["user"]["me"], token: string) {
      Sentry.setUser({
        email: user.email,
        id: user.id,
        username: user.name ?? `User: ${user.email}`,
      });
      state.meData = user;
      const tokenUser = jwt_decode<JWT>(token);
      state.token = token;
      // state.name = tokenUser.properties.name;
      state.name = user.name;
      state.email = user.email;
      state.id = user.id;
      state.isAnonymous = !!(tokenUser.properties as AnonymousUser)
        ?.acceptTerms;
      // @ts-expect-error fix the types
      if (tokenUser.properties.accountId)
        // @ts-expect-error fix the types
        state.accountId = tokenUser.properties.accountId!;
      state.loadingAuth = false;
      state.emailVerified = !!(user as User).emailVerified;
    },
    setMeData(s, data: RouterOutput["user"]["me"]) {
      s.meData = data;
    },
    set: (s, data: any) => {
      Object.keys({ ...data }).forEach((k) => (s[k] = data[k]));
    },
  })
  .computed({
    isAuth: (s) => !!s.token && !!s.id,
  })
  .methods(({ getActions, getState }) => ({
    refetch: async () => {
      const meData = await trpcClient.user.me.query();
      getActions().setMeData(meData);
    },
    setupByToken: async (
      // state,
      token: string = localStorage.getItem("ff:token") as string
    ) => {
      const state = getState();
      if (!token) {
        getActions().set({ loadingAuth: false });
        return;
      }
      const tokenUser = jwt_decode<JWT>(token);
      // TODO, better anon
      const isAnonymous = !!(tokenUser.properties as AnonymousUser)
        ?.acceptTerms;
      // so API can use token to call
      localStorage.setItem("ff:token", token);
      const [err, user] = await to(trpcClient.user.me.query({ isAnonymous }));
      if (err) {
        getActions().set({ loadingAuth: false });
        return;
      }
      console.log({ user });
      if (!user) {
        getActions().set({ loadingAuth: false });
      } else {
        getActions().setupUser(user, token);
      }
    },
    anonymousUserCheck: async (
      { email, reviewId, name },
      { checkOnly = true } = {}
    ) => {
      // const { setupByToken } = getActions();

      const status = await client
        .get<{ user?: AnonymousUser; token?: string; exist: boolean }>(
          `/auth/anon-user/${checkOnly ? "check" : "login"}`,
          {
            params: { email, reviewId: reviewId, name },
          }
        )
        .then((d) => d.data);
      // if (status.exist) {
      //   setupByToken(status.token);
      // }
      return status;
    },
  }))

  .build();

export const useAuth = use;

// interface BearState {
//   bears: number;
//   increase: (by: number) => void;
// }

// const useAuth = create<{}>()((set) => ({
//   token: "",
//   id: "",
//   email: "",
//   setupByToken: (token) => set((state) => {
//     const tokenUser = jwt_decode(token);
//     state.
//   }),
// }));

import { useEffect, useState } from "react";
import { useAuth } from "@stores/auth";
import { FLoader } from "@components/Loader/FullPageLoader";
import { useRouter } from "@tanstack/react-location";

const Nanobar = (window as any).Nanobar;

var nanobar = new Nanobar();

const useNanoBar = ({ loadingAuth }) => {
  const [loading, setLoading] = useState(false);
  const router = useRouter<any>();
  useEffect(() => {
    if (!loading && (router.pending || loadingAuth)) {
      nanobar.go(30);
      setLoading(true);
    } else if (loading && !router.pending && !loadingAuth) {
      nanobar.go(100);
      setLoading(false);
    }
  }, [loading, router.pending, loadingAuth]);
  return [loading, setLoading] as const;
};

export default function App({ children }: { children: React.ReactNode }) {
  const [{ loadingAuth }, { setupByToken }] = useAuth();
  useNanoBar({ loadingAuth });
  const router = useRouter<any>();
  useEffect(() => {
    const t =
      new URLSearchParams(window.location.search).get("token") ?? undefined;
    setupByToken(t);
  }, []);
  useEffect(() => {
    if (localStorage.getItem("chakra-ui-color-mode") === 'dark') {
      localStorage.removeItem("chakra-ui-color-mode")
    }
  }, [])
  // can be list of loading deps
  const isLoading = loadingAuth;

  return <>{children}</>;
}

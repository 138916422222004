import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

// default base style from the Input theme
const baseStyle = defineStyle({
  fontFamily: "inter",
  fontSize: "md",
  fontWeight: "normal",
  lineHeight: "140%",
});

export const headline_1 = defineStyle({
  fontWeight: "bold",
  fontSize: "14",
  lineHeight: "110%",
});
export const headline_2 = defineStyle({
  fontWeight: "bold",
  fontSize: "5xl",
  lineHeight: "120%",
});
export const headline_3 = defineStyle({
  fontWeight: "semibold",
  fontSize: "8",
  lineHeight: "120%",
});
export const headline_4 = defineStyle({
  fontWeight: "semibold",
  fontSize: "24px",
  lineHeight: "120%",
});
export const headline_5 = defineStyle({
  fontWeight: "semibold",
  fontSize: "md",
  lineHeight: "120%",
});
export const body1 = defineStyle({
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "22.4px",
});
export const body1m = defineStyle({
  fontWeight: "500",
});
export const body1b = defineStyle({
  fontWeight: "700",
});
export const body2 = defineStyle({
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "19.6px",
});
export const body3 = defineStyle({
  fontSize: "xs",
});
export const body4 = defineStyle({
  fontSize: "0.71428571rem",
});
export const fileDescription1 = defineStyle({
  fontWeight: "400",
  fontSize: "0.75rem",
  lineHeight: "3",
});
export const fileDescription2 = defineStyle({
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "12px",
});
export const menuText = defineStyle({
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "19.6px",
});
export const button1 = defineStyle({
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "16px",
});

export const fileTime = defineStyle({
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "12px",
});

export const subMenuText = defineStyle({
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "14px",
});

export const textStyles = {
  headline_1,
  headline_2,
  headline_3,
  headline_4,
  headline_5,
  body1,
  body1b,
  body1m,
  body2,
  body3,
  body4,
  fileDescription1,
  fileDescription2,
  menuText,
  subMenuText,
  button1,
  fileTime,
};

export const TextTheme = defineStyleConfig({
  baseStyle,
  variants: textStyles,
});

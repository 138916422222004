import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  button: {},
  list: {
    // this will style the MenuList component
    py: "4",
    borderRadius: "xl",
    border: "none",
  },
  item: {
    color: "brandGray.500",
    _hover: {
      bg: "blackAlpha.300",
    },
    _focus: {
      bg: "blackAlpha.300",
    },
    _dark: {
      color: "white",
    },
  },
  groupTitle: {},
  command: {},
  divider: {},
});

const smallMenu = definePartsStyle({
  // define the part you're going to style
  button: {},
  list: {
    // this will style the MenuList component
    p: "9px",
    minW: "149px",
    borderRadius: "xl",
    border: "none",
    bg: "rgba(245, 245, 245, 1)",
  },
  item: {
    py: 0,
    display: "flex",
    flexDir: "row-reverse",
    minW: "130px",
    color: "888",
    "&[aria-checked='true']": {
      color: "CI_Gary2",
      fontWeight: 600,
    },
    ".chakra-menu__icon-wrapper": {
      mr: 0,
      ml: 2,
      color: "CI_Purple1",
    },
    bg: "transparent",
  },
  groupTitle: {},
  command: {},
  divider: {},
});
// export the base styles in the component theme
export const menuTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { smallMenu },
});

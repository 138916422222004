import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const purple = definePartsStyle({
  header: {
    bg: `CI_Purple1`,
    textStyle: "headline_5",
    color: "white",
  },
  dialog: {
    bg:"f5f5f5",
    overflow: "hidden",
    borderRadius: "md",
    color: "888",
    fontStyle: "body2",

    // Let's also provide dark mode alternatives
    _dark: {
      color: "white",
    },
  },
  body: {
    mt: 3,
  }
});

export const modalTheme = defineMultiStyleConfig({
  variants: { purple },
});

// Now we can use the new `purple` variant
// <Modal variant='purple' ... />
